<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_service.delivery_schedule') }}</h4>
      </template>
      <template v-slot:body>
        <b-row v-if="!authUser.warehouse_id">
          <b-col md="6" sm="12" xs="12">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.from_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.to_date')"
              label-for="from_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.to_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
        </b-row>
         <b-row>
          <b-col md="6" sm="12" xs="12" class="col-md-6" v-if="authUser.warehouse_id">
            <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="commodity_group_id"
            :label="$t('warehouse_information.warehouse_name')"
            >
            <b-form-select
              plain
              v-model="search.warehouse_id"
              :options="warehouseList"
              id="commodity_group_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12" xs="12" v-if="authUser.warehouse_id">
              <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.from_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="authUser.warehouse_id">
          <b-col md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.to_date')"
              label-for="from_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.to_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12" xs="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.delivery_schedule_list') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="excelData"
                  :title="$t('warehouse_service.delivery_schedule_list')"
                  worksheet="Released Schedule List Sheet"
                  name="filename.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
                <!-- <export-excel
                    class="btn btn-danger"
                    :data="excelData"
                    worksheet="Released Schedule List Sheet"
                    type="csv"
                    name="filename.xls">
                    {{ $t('globalTrans.export_csv') }}
                </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('warehouse_service.delivery_schedule_list')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="deliveryScheduleList" :fields="columns" :tbody-tr-class="rowClass" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                      {{ getFarmerData(data.item.farmer_id, 'name') }}
                    </template>
                    <template v-slot:cell(mobile_no)="data">
                      {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(warehouse_id)="data">
                      <span> {{ data.item.warehouse_id }}</span>
                    </template>
                    <template v-slot:cell(crop_type_id)="data">
                      <span>{{ data.item.crop_type_id }}</span>
                    </template>
                    <template v-slot:cell(crop_id)="data">
                      <span> {{ data.item.crop_id }}</span>
                    </template>
                    <template v-slot:cell(land_area)="data">
                      <span> {{ $n(getFarmerData(data.item.farmer_id, 'land')) }}
                      </span>
                    </template>
                    <template v-slot:cell(delivery_date)="data">
                      <span> {{ data.item.delivery_date | dateFormat }}
                      </span>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { deliveryScheduleList } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
      ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        from_date: '',
        to_date: ''
      },
      testId: 0,
      deliveryScheduleList: [],
      excelData: [],
      farmerList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_service.farmer_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.mobile'), class: 'text-left' },
          { label: this.$t('warehouse_report.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.land_area'), class: 'text-left' },
          { label: this.$t('warehouse_service.delivery_date'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'warehouse_name_bn' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'land_area' },
          { key: 'delivery_date' }

        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'warehouse_name' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'land_area' },
          { key: 'delivery_date' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.getFarmerList()
     if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    getFarmerList () {
        RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
        if (response) {
            this.farmerList = response.data
            }
        })
    },
    paginationDataLoad () {
      this.loadData()
    },
    rowClass (item, type) {
        var day = Math.ceil((Math.abs(new Date(item.delivery_date) - new Date())) / (1000 * 60 * 60 * 24))
        if (day <= 7 && type === 'row') {
            return 'table-danger'
        } else {
            return ''
        }
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: ''
      }
    },
    searchData () {
        if (this.search.from_date !== '' || this.search.to_date !== '') {
            this.loadData()
            this.showData = true
            this.showHeading = true
        }
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(warehouseServiceBaseUrl, deliveryScheduleList, this.search).then(response => {
            if (response.success) {
                this.dataList(response)
            }
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    dataList (data) {
     this.deliveryScheduleList = data.data
     var serial = 0
     const rowData = data.data.map((keyItem, index) => {
         serial += 1
         if (this.$i18n.locale === 'en') {
             return {
                'sl ': this.$n(serial),
                'Farmer Name': this.getFarmerData(keyItem.farmer_id, 'name'),
                'Mobile ': keyItem.farmer_id,
                'Warehouse Name': keyItem.warehouse_name,
                'Crop Type': keyItem.commodity_group_name,
                'Crop Name': keyItem.commodity_name,
                'Land Area': this.getFarmerData(keyItem.farmer_id, 'land'),
                'Date ': keyItem.delivery_date
            }
         } else {
             return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'কৃষকের নাম': this.getFarmerData(keyItem.farmer_id, 'name'),
                'মোবাইল নম্বর': '০' + this.$n(keyItem.farmer_id, { useGrouping: false }),
                'গুদামের নাম': keyItem.warehouse_name_bn,
                'শস্যের ধরণ': keyItem.commodity_group_name_bn,
                'শস্যের নাম': keyItem.commodity_name_bn,
                'জমির অঞ্চল': this.getFarmerData(keyItem.farmer_id, 'land'),
                'তারিখ ': this.$n(keyItem.delivery_date.split('-')[2], { useGrouping: false }) + '/' + this.$n(keyItem.delivery_date.split('-')[1], { useGrouping: false }) + '/' + this.$n(keyItem.delivery_date.split('-')[0], { useGrouping: false })
            }
         }
     })
    this.excelData = rowData
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.farmer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.mobile'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.warehouse_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.land_area'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_service.delivery_date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Delivery Schedule List' : 'বিতরণ সময়সূচী তালিকা'
    //   const columnWids = ['10%', '10%', '10%', '10%', '15%', '12%', '12%']
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'name' : 'name_bn' },
          { key: this.$i18n.locale === 'en' ? 'mobile_no' : 'mobile_no_bn' },
          { key: this.$i18n.locale === 'en' ? 'warehouse_name' : 'warehouse_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'commodity_group_name' : 'commodity_group_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'commodity_name' : 'commodity_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'land_area' : 'land_area' },
          { key: this.$i18n.locale === 'en' ? 'delivery_date' : 'delivery_date' }
        ]
      var serial = 0
      const listData = this.deliveryScheduleList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'mobile_no') {
                return { text: item.farmer_id }
            }
            if (keyItem.key === 'mobile_no_bn') {
                return { text: '০' + this.$n(item.farmer_id, { useGrouping: false }) }
            }
            if (keyItem.key === 'name') {
                return { text: this.farmerList.filter(document => document.username === item.farmer_id)[0].name }
            }
            if (keyItem.key === 'name_bn') {
                return { text: this.farmerList.filter(document => document.username === item.farmer_id)[0].name_bn }
            }
            if (keyItem.key === 'land_area') {
                 return { text: this.$n(this.farmerList.filter(document => document.username === item.farmer_id)[0].land_area) }
            }
            if (keyItem.key === 'delivery_date') {
                 return { text: this.$n(item.delivery_date.split('-')[2], { useGrouping: false }) + '/' + this.$n(item.delivery_date.split('-')[1], { useGrouping: false }) + '/' + this.$n(item.delivery_date.split('-')[0], { useGrouping: false }) }
            }
          return { text: item[keyItem.key] }
        })
        return rowData
      })
      return listData
    },
    getFarmerData (farmerId, key = 'name') {
        const farmerdata = this.farmerList.find(item => item.username === farmerId || item.mobile_no === farmerId)
        if (farmerdata) {
            if (key === 'name') {
                return this.$i18n.locale === 'en' ? farmerdata.name : farmerdata.name_bn
            }
            if (key === 'father') {
                return this.$i18n.locale === 'en' ? farmerdata.father_name : farmerdata.father_name_bn
            }
            if (key === 'land') {
                // return this.$n(parseFloat(farmerdata.land_area))
                return farmerdata.land_area
            }
        } else {
            return null
        }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
